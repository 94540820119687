/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as builder from "../../ducks/builder";
import { toAbsoluteUrl } from "../../utils/utils";
import {Card} from "react-bootstrap";
import { FormattedMessage } from "react-intl";

class Brand extends React.Component {
  componentDidMount() {
    // eslint-disable-next-line no-undef
  }

  render() {
    return (
      <div
        className={`col-md-2 kt-header__brand ${this.props.brandClasses} kt-grid__item`}
        id="kt_header_brand"
      >
         <Link to="" className="text-white">
        <FormattedMessage id="register_form" />
      </Link>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    brandClasses: builder.selectors.getClasses(store, {
      path: "brand",
      toString: true
    })
  };
};

export default connect(mapStateToProps)(Brand);
