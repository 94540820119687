export default {
    header: {
        self: {},
        items: [
            {
                root: true,
                title: 'Dashboard',
                alignment: "left",
                page: "dashboard",
                translate: 'dashboard',
            },
            {
                title: "Gruplar",
                page: "group",
                root: true,
                alignment: "left",
                translate: 'groups',
            }, 
            {
                title: "Kurslar",
                page: "course",
                root: true,
                alignment: "left",
                translate: 'courses',

            },
            {
                title: "Kurs Tipleri",
                page: "ctype",
                root: true,
                alignment: "left",
                translate: 'ctype',
            },
            {
                title: "Siteler",
                page: "site",
                root: true,
                alignment: "left",
                translate: 'sites',
            },
            {
                title: "Sehirler",
                page: "country/2/city",
                root: true,
                alignment: "left",
                translate: 'cities',
            },
            {
                title: "Tanımlar",
                root: true,
                alignment: "left",
                toggle: "click",
                translate: 'definitions',
                submenu: [
                    {
                        title: "Amaclar",
                        page: "purpose",
                        bullet: "line",
                        translate: 'purpose',
                    },
                    {
                        title: "Zamanlar",
                        page: "time",
                        bullet: "line",
                        translate: 'timeid',
                    },
                    {
                        title: "Grup Tipleri",
                        page: "grouptype",
                        bullet: "line",
                        translate: 'grouptype',
                    },
                    {
                        title: "Durumlar",
                        page: "status",
                        bullet: "line",
                        translate: 'status',
                    }
                ]
            },
            {
                title: "Kursiyerler",
                page: "kursiyerler",
                root: true,
                alignment: "left",
                translate: 'trainees',
            },
            {
                title: "Kullanıcılar",
                page: "kullanicilar",
                root: true,
                alignment: "left",
                translate: 'users',
            },
        ]
    }
};
