/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Nav, Tab, Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import { ReactComponent as CompilingIcon } from "../../../_metronic/layout/assets/layout-svg-icons/Compiling.svg";
import Moment from 'react-moment';
import { toAbsoluteUrl } from "../../../_metronic/utils/utils";

import swal from 'sweetalert2';
import { tokenized } from '../../api';

function notifications({ connected, count, notifications }) {
  var closed = 0;
  var ws;

  async function connnect_websocket() {
    var user = await tokenized.get("/user/me");
    var user_id = user.data.id;

    var url;

    if(window.location.host.split(":")[0] === "localhost") {
      url = "ws://" + window.location.host.split(":")[0] + ":5000";
    } else if(window.location.host.split(":")[0] === "n.narusta.com") {
      url = "wss://narusta.com";
    } else {
      url = "wss://" + window.location.host.split(":")[0];
    }

    let _ws = new WebSocket(url + "/socket?user_id=" + user_id);

    return await new Promise(function(resolve, reject) {
      var open = function() {
        resolve(_ws);
        _ws.removeEventListener('open', open);
      };

      var close = function() {
        reject();
        _ws.removeEventListener('close', close);
      };

      _ws.addEventListener('open', open);
      _ws.addEventListener('close', close);
    });
  }

  async function web_socket_task() {
    let notificationTask, _notifications;
  
    loop1: while(!closed) {
      try {
        ws = await connnect_websocket();
      } catch(e) {
        console.error('Error establishing WebSocket connection: ', e);
        continue loop1;
      }

      let disconnect = new Promise(resolve => ws.onclose = resolve);
      connected(true);
  
      ws.onmessage = async function (evt) {
        await notificationTask;
  
        var received_msg = evt.data;
        var data = JSON.parse(received_msg);

        ++_notifications.data.unread;
        _notifications.data.data.unshift({ id: new Date().getTime(), data: received_msg, created_at: new Date() });

        count(_notifications.data.unread);
        notifications(_notifications.data.data);

        if(data.link) {
            swal.fire({
                title: 'Bildirim',
                html: data.message,
                icon: 'warning',
                buttons: ["Siparise git", "Sayfada kal!"]
            })
            .then(function(data2) {
                if(!data2) {
                    window.location = data.link;
                }
            });
        } else {
            swal.fire({
                title: 'Bildirim',
                html: data.message,
                icon: 'warning'
            });
        }
      };
  
      notificationTask = tokenized.get("/api/notification");
  
      _notifications = await notificationTask;
      count(_notifications.data.unread);
      notifications(_notifications.data.data);
  
      await disconnect;
      connected(false);
  
      if(!closed) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  }

  web_socket_task();

  return function() {
    closed = 1;
    ws && ws.close();
  };
}

export default class UserNotifications extends React.Component {
  state = { key: "Alerts", connected: false, unread: 0, notifications: [] };

  componentDidMount() {
    var that = this;

    this.disconnect = notifications({
      connected(connected) {
        that.setState({ connected });
      },
      count(unread) {
        that.setState({ unread });
      },
      notifications(notifications) {
        that.setState({ notifications });
      }
    });
  }

  async handleNotificationClick(n, e) {
    e.preventDefault();

    if(!n.read_at) {
      --this.state.unread;
      n.read_at = new Date();
      this.forceUpdate();

      await tokenized.delete('/api/notification/' + n.id);
    }

    // TODO: link varsa linke git
  }

  async handleReadAll(e) {
    e.preventDefault();

    var prevCount = this.state.unread;
    for(var n of this.state.notifications) {
      if(!n.read_at) {
        --this.state.unread;
        n.read_at = new Date();
      }
    }

    if(prevCount !== this.state.unread) {
      this.forceUpdate();
      await tokenized.delete('/api/notification');
    }
  }

  componentWillUnmount() {
    this.disconnect();
  }
  
  render() {
    if(!this.state.connected)
      return null;

    // eslint-disable-next-line no-unused-vars
    const { key } = this.state;

    return (
      <Dropdown className="kt-header__topbar-item mr-3" drop="down" alignRight>
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-notifications"
        >
          <span className="kt-header__topbar-icon kt-pulse">
            <span className="kt-svg-icon">
              <CompilingIcon />
            </span>

            <span className="kt-pulse__ring" hidden={!this.state.unread} />
            <span className="kt-badge kt-badge--dot kt-badge--notify kt-badge--sm">{ this.state.unread }</span>
            { (this.state.unread && <span style={{ fontSize: '14px', color: '#FFF', background: 'red', padding: '0px 4px', borderRadius: '50%' }}>{ this.state.unread }</span>) || null }
          </span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
          <form>
            {/** Head */}
            <div
              className="kt-head kt-head--skin-dark kt-head--fit-x kt-head--fit-b"
              style={{ backgroundImage: 'url(' + toAbsoluteUrl("/media/misc/bg-1.jpg") + ')' }}
            >
              <h3 className="kt-head__title">
                User Notifications
                {(this.state.unread && <span onClick={this.handleReadAll.bind(this)} className='ml-2 btn btn-sm btn-bold btn-font-md btn-success'>
                  { this.state.unread } new
                </span>) || null}
              </h3>

              <Tab.Container
                defaultActiveKey={key}
                className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success kt-notification-item-padding-x"
              >
                <Nav
                  className="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-success kt-notification-item-padding-x"
                  onSelect={_key => this.setState({ key: _key })}
                >
                  <Nav.Item className="nav-item">
                    <Nav.Link eventKey="Alerts" className="nav-link show">
                      Alerts
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="Alerts">
                    { (this.state.notifications && this.state.notifications.length && <PerfectScrollbar
                      options={{
                        wheelSpeed: 2,
                        wheelPropagation: false
                      }}
                      style={{ maxHeight: "100vh", position: "relative" }}
                    >
                      <div
                        className="kt-notification kt-margin-t-10 kt-margin-b-10"
                        style={{ maxHeight: "40vh", position: "relative" }}
                      >
                        <div
                          className="kt-notification kt-margin-t-10 kt-margin-b-10 kt-scroll"
                          data-scroll="true"
                          data-height="300"
                          data-mobile-height="200"
                        >
                          {this.state.notifications.map(n => <a onClick={this.handleNotificationClick.bind(this, n)} className={"kt-notification__item " + (n.read_at ? 'nt-seen' : 'nt-not-seen')} key={n.id}>
                            <div className="kt-notification__item-icon">
                              <i className="flaticon-alert kt-font-success" />
                            </div>
                            <div className="kt-notification__item-details">
                              <div className="kt-notification__item-title" dangerouslySetInnerHTML={{ __html: JSON.parse(n.data).message }} />
                              <div className="kt-notification__item-time">
                                <Moment date={n.created_at}
                                        durationFromNow />
                              </div>
                            </div>
                          </a>)}
                        </div>
                      </div>
                    </PerfectScrollbar>) || <div
                      className="kt-grid kt-grid--ver"
                      style={{ minHeight: "200px" }}
                      >
                      <div className="kt-grid kt-grid--hor kt-grid__item kt-grid__item--fluid kt-grid__item--middle">
                        <div className="kt-grid__item kt-grid__item--middle kt-align-center">
                          All caught up!
                          <br />
                          No new notifications.
                        </div>
                      </div>
                    </div> }
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </form>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}
