import React from "react";
import UserNotifications from "../../../app/partials/layout/UserNotifications";
import MyCart from "../../../app/partials/layout/MyCart";
import QuickActionsPanel from "../../../app/partials/layout/QuickActionsPanel";
import QuickPanelToggler from "./QuickPanelToggle";
import UserProfile from "../../../app/partials/layout/UserProfile";
import LanguageSelector from "../../../app/partials/layout/LanguageSelector";

import { toAbsoluteUrl } from "../../utils/utils";

export default class Topbar extends React.Component {
  render() {
    return (
      <div className="kt-header__topbar kt-grid__item">
        <LanguageSelector iconType="" />
        <UserProfile showHi={true} showBadge={true} />
      </div>
    );
  }
}
