import React, {Suspense, lazy} from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import 'react-dual-listbox/lib/react-dual-listbox.css';

const KullanicilarListe = React.lazy(() =>
    import('../Kullanicilar/List')
);
const KullanicilarEkle = React.lazy(() =>
    import('../Kullanicilar/Ekle')
);

const KursiyerListe = React.lazy(() =>
    import('../Kursiyerler/List')
);
const KursiyerEkle = React.lazy(() =>
    import('../Kursiyerler/Ekle')
);

const CourseListe = React.lazy(() =>
    import('../Course/List')
);
const CourseEkle = React.lazy(() =>
    import('../Course/Ekle')
);

const PurposeListe = React.lazy(() =>
    import('../Purpose/List')
);
const PurposeEkle = React.lazy(() =>
    import('../Purpose/Ekle')
);

const GroupTypeList = React.lazy(() =>
    import('../GroupType/List')
);

const GroupTypeAdd = React.lazy(() =>
    import('../GroupType/Ekle')
);

const TimeList = React.lazy(() =>
    import('../Time/List')
);

const TimeAdd = React.lazy(() =>
    import('../Time/Ekle')
);

const CourseTypeEkle = React.lazy(() =>
    import('../CourseType/Ekle')
);

const CourseTypeList = React.lazy(() =>
    import('../CourseType/List')
);

const CountryEkle = React.lazy(() =>
    import('../Country/Ekle')
);

const CountryList = React.lazy(() =>
    import('../Country/List')
);

const CityEkle = React.lazy(() =>
    import('../City/Ekle')
);

const CityList = React.lazy(() =>
    import('../City/List')
);

const DashboardEkle = React.lazy(() =>
    import('../CourseType/Ekle')
);
const DashboardListe = React.lazy(() =>
    import('../CourseType/List')
);


const SiteEkle = React.lazy(() =>
    import('../Site/Ekle')
);
const SiteListe = React.lazy(() =>
    import('../Site/List')
);

const GrupEkle = React.lazy(() =>
    import('../Group/Ekle')
);

const GrupListe = React.lazy(() =>
    import('../Group/List')
);

const StatusEkle = React.lazy(() =>
    import('../Status/Ekle')
);
const StatusListe = React.lazy(() =>
    import('../Status/List')
);


const Statistics = React.lazy(() =>
    import('../Statistics/Index')
);

export default function HomePage() {
    return (
        <Suspense>
            <Switch>
                {
                    <Redirect exact from="/" to="/dashboard"/>
                }

                <Route path="/dashboard" component={Statistics}/>

                <Route path="/ctype/:id/edit" component={CourseTypeEkle}/>
                <Route path="/ctype/create" component={CourseTypeEkle}/>
                <Route path="/ctype" component={CourseTypeList}/>

                <Route path="/course/:id/edit" component={CourseEkle}/>
                <Route path="/course/create" component={CourseEkle}/>
                <Route path="/course" component={CourseListe}/>

                <Route path="/purpose/:id/edit" component={PurposeEkle}/>
                <Route path="/purpose/create" component={PurposeEkle}/>
                <Route path="/purpose" component={PurposeListe}/>

                <Route path="/city/:id/edit" component={CityEkle}/>
                <Route path="/country/:country_id/city/create" component={CityEkle}/>
                <Route path="/country/:country_id/city" component={CityList}/>


                <Route path="/country/:id/edit" component={CountryEkle}/>
                <Route path="/country/create" component={CountryEkle}/>
                <Route path="/country" component={CountryList}/>

                <Route path="/time/:id/edit" component={TimeAdd}/>
                <Route path="/time/create" component={TimeAdd}/>
                <Route path="/time" component={TimeList}/>

                <Route path="/grouptype/:id/edit" component={GroupTypeAdd}/>
                <Route path="/grouptype/create" component={GroupTypeAdd}/>
                <Route path="/grouptype" component={GroupTypeList}/>

                <Route path="/kursiyerler/:id/edit" component={KursiyerEkle}/>
                <Route path="/kursiyerler/create" component={KursiyerEkle}/>
                <Route path="/kursiyerler" component={KursiyerListe}/>

                <Route path="/site/:id/edit" component={SiteEkle}/>
                <Route path="/site/create" component={SiteEkle}/>
                <Route path="/site" component={SiteListe}/>

                <Route path="/status/:id/edit" component={StatusEkle}/>
                <Route path="/status/create" component={StatusEkle}/>
                <Route path="/status" component={StatusListe}/>

                <Route path="/group/:id/edit" component={GrupEkle}/>
                <Route path="/group/create" component={GrupEkle}/>
                <Route path="/group" component={GrupListe}/>

                <Route path="/kullanicilar/:id/edit" component={KullanicilarEkle}/>
                <Route path="/kullanicilar/create" component={KullanicilarEkle}/>
                <Route path="/kullanicilar" component={KullanicilarListe}/>

                <Redirect to="/error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
